import { Col, Dropdown, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Button as btn } from 'react-bootstrap';
import { InputSelect } from '~/components/NovosInputs';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  /* display: flex;
  justify-content: center;
  align-items: center; */

  .css-1h65th5-ButtonBase {
    background-color: #8850bf !important;
    box-shadow: transparent !important;
    border-color: #8850bf !important;
    border-radius: 5px;
    color: white !important;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    height: 50px !important;
    width: 100% !important;
    margin-top: 13px;
    font-size: 14px !important;
  }

  .white-placeholder {
    background-color: #28a745 !important;
    color: #fff !important;
    border: 2px solid black;
  }

  .white-placeholder::placeholder {
    color: #fff;
  }

  .white-placeholder:disabled {
    cursor: not-allowed;
  }

  .css-iuba1a-ButtonBase:hover {
    background-color: #8850bf !important;
    box-shadow: transparent !important;
    border-color: #8850bf !important;
    border-radius: 5px;
    color: white !important;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    height: 50px !important;
    width: 100% !important;
    margin-top: 13px !important;
    font-size: 14px !important;
  }

  .css-iuba1a-ButtonBase {
    background-color: #8850bf !important;
    box-shadow: transparent !important;
    border-color: #8850bf !important;
    border-radius: 5px;
    color: white !important;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    height: 50px !important;
    width: 100% !important;
    margin-top: 13px !important;
    font-size: 14px !important;
  }

  .css-1h65th5-ButtonBase:active {
    color: white !important;
  }

  .css-1h65th5-ButtonBase:hover {
    color: white !important;
  }
`;
export const RowBtp = styled(Row)`
  width: 100%;
  padding: 0px 20px 0px 20px;
`;

export const ColBtp = styled(Col)`
  padding: 0;
`;

export const FormBtp = styled(Form)`
  width: 100%;
  padding: 10px 0px 0px 30px;

  label {
    font-weight: 100;
    font-size: 12px;
    margin-bottom: 5px;
  }
  .form-control {
    background-color: #312e38;
    border-color: #3d3b45;
    color: #adadad;
    font-size: 15px;

    &::placeholder {
      color: #6b6b6b;
      font-size: 15px;
    }

    .rowStyle {
      background: red !important;
      padding: 50px;
    }
  }
`;

export const bdXnLL = styled(Dropdown)`
  background-color: #8850bf !important;
  box-shadow: transparent !important;
  border-color: #8850bf !important;

  height: 50px;
  margin-top: 13px;
  font-size: 13px;

  &:active:focus {
    background-color: #8850bf !important;
  }
  &:focus {
    background-color: #8850bf !important;
    box-shadow: none;
  }
`;

export const TableContainer = styled.div`
  height: 100%;
  height: 300px;
  margin-top: 16px;
`;

export const CheckboxTableContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
