import Spinner from '@atlaskit/spinner';
import { yupResolver } from '@hookform/resolvers/yup';
import { GridRowParams } from '@material-ui/data-grid';
import { Fildset } from '~/components/Fildset';
import FormDefault from '~/components/FormDefault';
import { InputSelect, InputText } from '~/components/NovosInputs';
import Search from '~/components/Search';
import api from '~/services/api';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Separator from '~/components/Separator';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import Checkbox from '@atlaskit/checkbox';
import ModeloConsulta from './ModeloConsulta.json';
import { Tooltip } from '@material-ui/core';
import {
  Container,
  FormBtp,
  CheckboxTableContent,
  TableContainer,
} from './styles';
import { selectProps } from './types';
import { schema } from './Validations';

interface TipoDeDadosProps {
  cod_tipo_dados: number;
  des_tipo_dados: string;
}

interface ModeloConsulta {
  id: number;
  title: string;
  checked_tipo: number;
  intrucao: string;
  expressao: string;
}

const TipoDeInformacoes: React.FC = () => {
  const {
    register,
    control,
    reset,
    getValues,
    clearErrors,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const formBlank = {
    descricao: '',
    tipoDados: {
      label: undefined,
      value: undefined,
    },
    modelo: {
      label: undefined,
      value: undefined,
    },
    regex: '',
  };

  const [loader, setLoader] = useState<boolean>(false);
  const [listagemInfo, setListagemInfo] = useState<string>('');
  const [tipoExpressao, setTipoExpressao] = useState<number>(-1);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [modeloConsultas, setModeloConsultas] = useState<any>([]);
  const [tipoDados, setTipoDados] = useState<selectProps[]>([
    {
      label: '',
      value: 0,
    },
  ]);
  const [codTipoInfo, setCodTipoInfo] = useState<number | undefined>(undefined);
  const [valueSelect, setValueSelect] = useState<selectProps>({
    label: '',
    value: 0,
  });
  const [telasRelacionadas, setTelasRelacionadas] = useState<any>([]);

  useEffect(() => {
    if (isUpdate) {
      getTelas(codTipoInfo);
    }
  }, [isUpdate, codTipoInfo]);

  async function getTelas(cod_info: number | undefined) {
    try {
      const res = await api.get(`/tipo-de-informacoes/${cod_info}`);
      const { data } = res.data;

      const regs = data.map((item: any, index: number) => ({
        id: index,
        cod_tela: item.cod_tela,
        des_tela: item.des_tela,
        cod_campo: item.cod_campo,
        des_campo: item.des_campo,
        des_tipo_dados: item.des_tipo_dados,
        flg_filtro: item.flg_filtro,
        flg_visualizacao: item.flg_visualizacao,
      }));

      setTelasRelacionadas(regs);
    } catch (e: any) {
      console.log(e.message);
    }
  }

  const columns: GridColumns = [
    {
      field: 'cod_tela',
      headerName: 'Cód Tela',
      hide: true,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'des_tela',
      headerName: 'Tela',
      width: 330,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <Tooltip title={row.des_tela}>
            <span>{row.des_tela}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'cod_campo',
      headerName: 'Cód Campo',
      width: 130,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'des_campo',
      headerName: 'Campo',
      width: 200,
      disableColumnMenu: true,
      disableReorder: true,
    },
    {
      field: 'des_tipo_dados',
      headerName: 'Tipo',
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: 'flg_filtro',
      headerName: 'Filtro?',
      width: 150,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <CheckboxTableContent>
            <Checkbox
              isChecked={row.flg_filtro}
              style={{ cursor: 'not-allowed' }}
            />
          </CheckboxTableContent>
        );
      },
    },
    {
      field: 'flg_visualizacao',
      headerName: 'Visível Grade?',
      width: 200,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <CheckboxTableContent>
            <Checkbox
              isChecked={row.flg_visualizacao}
              style={{ cursor: 'not-allowed' }}
            />
          </CheckboxTableContent>
        );
      },
    },
  ];

  const handleChangeListagemInfo = (listagem: any) => {
    const data2 = modeloConsultas.filter(
      (item: { label: any }) => item.label === listagem,
    );
    setListagemInfo(
      data2[0].intrucao
        .toLocaleLowerCase()
        .replaceAll(`'`, `"`)
        .replaceAll(`},{`, `,},{\n`)
        .replaceAll(`,`, `,\n`)
        .replaceAll(`union all`, `,\r\nunion all\r\n`)
        .replaceAll(`select`, `select\n`)
        .replaceAll(`from`, `\r\nfrom`)
        .replaceAll(`[{`, `[{\r\n`)
        .replaceAll(`}]`, `\r\n}]`),
    );
    if (valueSelect.value === 5 && data2[0].expressao !== '') {
      return setValue('regex', data2[0].expressao);
    }
  };

  useEffect(() => {
    const dados = ModeloConsulta.filter(
      (item) => item.checked_tipo === tipoExpressao,
    );
    setModeloConsultas(dados);
  }, [tipoExpressao]);

  useEffect(() => {
    (async () => {
      const result = await api.get('/tipo-de-informacoes');
      const options = result.data.data.map((item: TipoDeDadosProps) => {
        return {
          label: item.des_tipo_dados.toUpperCase(),
          value: item.cod_tipo_dados,
        };
      });
      setTipoDados(options);
    })();
  }, []);

  /**
   * Reseta form para criação de novo registro
   */
  const resetFormData = useCallback(() => {
    setListagemInfo('');
    setValueSelect({
      label: '',
      value: 0,
    });
    reset(formBlank);
    setTipoExpressao(-1);
    setCodTipoInfo(undefined);
    setUpdate(false);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setListagemInfo('');
    reset({
      modelo: '',
      tipoDados: getValues('tipoDados'),
      descricao: getValues('descricao'),
      regex: getValues('regex'),
    });
    setTipoExpressao(+event.target.value);
  };

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    const {
      cod_tipo_info,
      cod_tipo_dados,
      des_tipo_info,
      tipo_expressao,
      listagem_info,
      expressao_validacao,
    } = row;
    setValue('descricao', des_tipo_info);
    const option = tipoDados.filter(
      (item: selectProps) => item.value === cod_tipo_dados,
    );
    setValueSelect(option[0]);
    setValue('tipoDados', option[0]);
    if (option[0].value === 5) {
      setIsDisabled(false);
    }
    setValue('regex', expressao_validacao);
    setCodTipoInfo(cod_tipo_info);
    setTipoExpressao(tipo_expressao);
    setListagemInfo(listagem_info);
    setUpdate(true);
    setLoader(false);
    setShowSearch(false);
  };

  const handleSubmit = useCallback(async () => {
    if (tipoExpressao === -1) return toast.warn('O Tipo precisa ser informado');

    if (listagemInfo === '' || listagemInfo === undefined)
      return toast.warn('O Código precisa ser informado');

    const isValid = await trigger();
    if (!isValid) {
      toast.warn('Existe informações pendentes');
    } else {
      try {
        if (isUpdate) {
          const { data } = await api.put(
            `/tipo-de-informacoes/${codTipoInfo}`,
            {
              cod_tipo_dados: getValues('tipoDados'),
              des_tipo_info: getValues('descricao'),
              regex: getValues('regex'),
              tipo_expressao: tipoExpressao,
              listagemInfo,
            },
          );
          if (data.success) {
            toast.success(data.message);
            setUpdate(false);
            resetFormData();
            setShowSearch(true);
            return;
          }
        }
        const { data } = await api.post('/tipo-de-informacoes', {
          cod_tipo_dados: getValues('tipoDados'),
          des_tipo_info: getValues('descricao'),
          tipo_expressao: tipoExpressao,
          regex: getValues('regex'),
          listagemInfo,
        });
        if (data.success) {
          toast.success(data.message);
          resetFormData();
        }
      } finally {
        setLoader(false);
      }
    }
  }, [isUpdate, resetFormData, listagemInfo]);

  const handleDelete = async () => {
    const res = await api.delete(`/tipo-de-informacoes/${codTipoInfo}`);
    const { success, message } = res.data;
    if (!success) throw new Error(message);
    resetFormData();
    setShowSearch(true);
    toast.success(message);
  };

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={197}
        />
      )}
      {!showSearch && (
        <FormDefault
          codTela={197}
          title="Tipo De Informações"
          onSave={async () => {
            await handleSubmit();
          }}
          codigoRegistro={[
            {
              value: codTipoInfo,
              des_campo: 'Código',
            },
          ]}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={handleDelete}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          showSwitch={false}
        >
          <div className="row">
            <div className="col-sm-12 col-md-8">
              <InputText
                name="descricao"
                label="Descrição"
                placeholder="Informe a Descrição"
                register={register}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.target;
                  setValue('descricao', value.trimStart().toUpperCase());
                  clearErrors('descricao');
                }}
                isError={!!errors.descricao}
                maxLength={50}
              />
            </div>
            <div className="col-sm-12 col-md-4">
              <InputSelect
                options={tipoDados}
                label="Tipo de Dados"
                placeholder="Informe o Tipo de Dados"
                name="tipoDados"
                register={register}
                isError={!!errors.tipoDados}
                control={control}
                changeSelected={(selected: selectProps) => {
                  setValue('tipoDados', selected);
                  setValueSelect(selected);
                  if (selected.value === 5) {
                    setIsDisabled(false);
                  } else {
                    setValue('regex', '');
                    setIsDisabled(true);
                  }
                  clearErrors('tipoDados');
                }}
              />
            </div>
            <div className="col-sm-12 col-md-8" style={{ marginTop: '7.5px' }}>
              <Fildset label="Tipo">
                <>
                  <div
                    className="col-5"
                    style={{ marginLeft: '20%', marginTop: '2px' }}
                  >
                    <FormBtp.Check
                      inline
                      label="SQL"
                      type="radio"
                      value={0}
                      onChange={(e: any) => handleChange(e)}
                      checked={tipoExpressao === 0}
                      style={{ marginBottom: '0px' }}
                    />
                  </div>
                  <div className="col-4" style={{ marginTop: '2px' }}>
                    <FormBtp.Check
                      inline
                      label="JSON"
                      type="radio"
                      value={1}
                      onChange={(e) => handleChange(e)}
                      checked={tipoExpressao === 1}
                      style={{ marginBottom: '0px' }}
                    />
                  </div>
                </>
              </Fildset>
            </div>
            <div
              className="col-sm-12 col-md-4"
              style={{
                marginTop: '1rem',
              }}
            >
              <div>
                <InputSelect
                  options={modeloConsultas}
                  readOnly
                  placeholder="Adicionar Modelo de Consulta"
                  className="white-placeholder"
                  name="modelo"
                  isDisabled={tipoExpressao === -1}
                  register={register}
                  isError={!!errors.modelo}
                  control={control}
                  changeSelected={(selected: selectProps) => {
                    setValue('modelo', selected);
                    handleChangeListagemInfo(selected.label);
                  }}
                />
              </div>
            </div>
            <div
              className="row"
              style={{
                marginLeft: '0px',
              }}
            >
              <div
                className="col-12 border"
                style={{
                  marginTop: '30px',
                  paddingLeft: '0px',
                  paddingRight: '0px',
                }}
              >
                <AceEditor
                  placeholder=""
                  mode={tipoExpressao === 0 ? 'mysql' : 'json'}
                  theme="monokai"
                  name="blah2"
                  fontSize={14}
                  showPrintMargin
                  showGutter
                  highlightActiveLine
                  value={listagemInfo}
                  height="380px"
                  width="100%"
                  setOptions={{
                    showPrintMargin: false,
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                    setShowPrintMargin: false,
                    useWorker: false,
                  }}
                  onChange={(value) => {
                    setListagemInfo(value);
                  }}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-12">
              <InputText
                name="regex"
                label="Expressão regular para habilitar aplicação(máscara)"
                placeholder="Expressão Validação"
                register={register}
                disabled={isDisabled}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.target;
                  clearErrors('regex');
                  setValue('regex', value.trimStart());
                }}
                isError={!!errors.regex}
                maxLength={50}
              />
            </div>
            {isUpdate && (
              <>
                <div className="col-sm-12 col-md-10">
                  <Separator labelText="Presente em" />
                </div>
                <TableContainer>
                  <DataGrid
                    rows={telasRelacionadas}
                    columns={columns}
                    pageSize={5}
                    disableSelectionOnClick
                    localeText={{
                      noRowsLabel: 'Nenhum registro encontrado...',
                      columnMenuLabel: 'Menu',
                      columnMenuFilter: 'Filtrar',
                      columnMenuHideColumn: 'Esconder',
                      columnMenuUnsort: 'Não ordenar',
                      columnMenuSortAsc: 'Ordernar ASC',
                      columnMenuSortDesc: 'Ordernar DESC',
                      columnMenuShowColumns: 'Mostrar columnas',
                    }}
                  />
                </TableContainer>
              </>
            )}
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default TipoDeInformacoes;
