import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import { GridRowParams } from '@material-ui/data-grid';
import Search from '~/components/Search';
import FormDefault from '~/components/FormDefault';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema, Options } from './Validations';
import { InputNumber, InputSelect, InputText } from '~/components/NovosInputs';
import Categoria from '~/components/Categoria';
import DefaultLoader from '~/components/DefaultLoader';
import useDebounce from '~/hooks/useDebounce';
import api from '~/services/api';
import { Col, Row } from 'react-bootstrap';

import { Container } from './styles';
import { SelectProps } from '@material-ui/core';
import ToggleDefault from '~/components/ToggleDefault';

const TipoDeMovimentoDeCaixa: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const { debouncedFn } = useDebounce();
  const [loader, setLoader] = useState<boolean>(false);
  const [iniTipo, setIniTipo] = useState(false);
  const [iniMov, setIniMov] = useState(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [categoria, setCategoria] = useState<Options>({
    value: undefined,
    label: '',
  });
  const flg_proventoo = watch('flg_provento');

  const formBlank = {
    cod_tipo_mov: null,
    cod_tipo_pdv: null,
    des_tipo: '',
    tipo_mov: { value: null, label: 'Selecione o Tipoo' },
    tipo_operacao: { value: null, label: 'Selecione o Tipo' },
    cod_categoria: { value: null, label: 'Selecione' },
  };

  useEffect(() => {
    (async () => {
      if (errors.tipo_mov) {
        setIniMov(true);
      }
    })();
  }, [errors.tipo_mov]);

  useEffect(() => {
    (async () => {
      if (errors.tipo_operacao) {
        setIniTipo(true);
      }
    })();
  }, [errors.tipo_operacao]);

  const handleChangeCategoria = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setValue('cod_categoria', val.value);
      setCategoria({
        value: val.value,
        label: val.label,
      });
    },
    [],
  );

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    const {
      cod_tipo_mov,
      cod_tipo_pdv,
      cod_categoria,
      des_tipo,
      tipo_mov,
      tipo_operacao,
      flg_provento,
    } = row;

    setValue('cod_tipo_mov', cod_tipo_mov);
    setValue('cod_tipo_pdv', cod_tipo_pdv);
    setValue('des_tipo', des_tipo);
    setValue('flg_provento', flg_provento);

    debouncedFn(() => {
      setCategoria({
        value: cod_categoria,
        label: '',
      });
      setValue('cod_categoria', cod_categoria);
      if (tipo_mov === 0) {
        setValue('tipo_mov', {
          label: 'ENTRADA',
          value: 0,
        });
      } else {
        setValue('tipo_mov', {
          label: 'SAÍDA',
          value: 1,
        });
      }
      if (tipo_operacao === 0) {
        setValue('tipo_operacao', {
          label: 'OUTROS',
          value: 0,
        });
      } else if (tipo_operacao === 1) {
        setValue('tipo_operacao', {
          label: 'ABERTURA',
          value: 1,
        });
      } else {
        setValue('tipo_operacao', {
          label: 'FECHAMENTO',
          value: 2,
        });
      }
    }, 10);

    setUpdate(true);
    setShowSearch(false);
  };

  const resetFormData = () => {
    reset(formBlank);
    reset({
      flg_provento: false,
    });
    setValue('tipo_mov', 0);
    setValue('tipo_operacao', 0);
    setValue('des_tipo', '');
    setValue('cod_tipo_mov', '');
    setValue('cod_tipo_pdv', '');
    setCategoria({
      value: undefined,
      label: '',
    });
    setUpdate(false);
  };

  const onSubmit = handleSubmit(async (data1) => {
    if (data1.cod_tipo_mov <= 0 || data1.cod_tipo_pdv <= 0) {
      setLoader(false);
      return toast.warning(
        'Verifique se todos os campos foram preenchidos corretamente.',
      );
    }
    if (
      data1.des_tipo.trim() === 'NENHUM' ||
      data1.des_tipo.trim() === 'NENHUMA' ||
      data1.des_tipo.trim() === 'NENHUM(A)'
    ) {
      return toast.warn(
        'Descrição contém palavra não permitida "Nenhum/Nenhuma".',
      );
    }
    try {
      if (isUpdate) {
        setLoader(true);
        const res = await api.put(`/tipo-movimento/`, {
          cod_tipo_mov: data1.cod_tipo_mov,
          cod_tipo_pdv: data1.cod_tipo_pdv,
          cod_categoria: data1.cod_categoria,
          des_tipo: data1.des_tipo.trim(),
          tipo_mov: data1.tipo_mov.value,
          tipo_operacao: data1.tipo_operacao.value,
          flg_provento: data1.flg_provento,
        });
        const { success, message } = res.data;

        if (!success) {
          return toast.warning(message);
        }

        if (success) {
          resetFormData();
          setShowSearch(true);
          return toast.success(message);
        }
      }
      const res = await api.post('/tipo-movimento', {
        cod_tipo_mov: data1.cod_tipo_mov,
        cod_tipo_pdv: data1.cod_tipo_pdv,
        cod_categoria: data1.cod_categoria,
        des_tipo: data1.des_tipo.trim(),
        tipo_mov: data1.tipo_mov.value,
        tipo_operacao: data1.tipo_operacao.value,
        flg_provento: data1.flg_provento,
      });

      const { success, message } = res.data;
      if (success) {
        resetFormData();
        setLoader(false);
        return toast.success(message);
      }
    } finally {
      setLoader(false);
    }
  });

  const handleDelete = async () => {
    const values = getValues();
    const { cod_tipo_mov } = values;
    const res = await api.delete(`/tipo-movimento/${cod_tipo_mov}`);
    const { success, message } = res.data;
    if (!success) {
      return toast.warning(message);
    }
    toast.success(message);
    resetFormData();
    setShowSearch(true);
  };

  const onNewData = () => {
    resetFormData();
    setUpdate(false);
    setShowSearch(false);
  };

  if (loader) {
    return <DefaultLoader />;
  }

  return (
    <Container>
      {showSearch && (
        <Search
          codTela={276}
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={276}
          title="Tipo De Movimento De Caixa"
          codigoRegistro={[
            {
              des_campo: 'Código',
              value: getValues('cod_tipo_mov'),
            },
          ]}
          onSave={async () => {
            await onSubmit();
          }}
          onCancel={() => {
            resetFormData();
            setUpdate(false);
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={onNewData}
          onDelete={handleDelete}
          onClearFields={resetFormData}
          onReturnSearch={() => {
            resetFormData();
            setShowSearch(true);
          }}
        >
          <Row>
            <Col sm={12} md={2} className="mt-4">
              <InputNumber
                label="Tipo Mov."
                name="cod_tipo_mov"
                placeholder="0"
                maxLength={10}
                max={999}
                min={1}
                register={register}
                disabled={isUpdate}
                isError={!!errors.cod_tipo_mov}
                control={control}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (String(e.target.value).length <= 8)
                    setValue('cod_tipo_mov', Math.abs(Number(e.target.value)));
                }}
              />
            </Col>
            <Col sm={12} md={2} className="mt-4">
              <InputNumber
                label="Cód PDV"
                name="cod_tipo_pdv"
                placeholder="0"
                maxLength={40}
                max={999}
                min={1}
                register={register}
                isError={!!errors.cod_tipo_pdv}
                control={control}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (String(e.target.value).length <= 8)
                    setValue('cod_tipo_pdv', Math.abs(Number(e.target.value)));
                }}
              />
            </Col>
            <Col sm={12} md={6} className="mt-4">
              <InputText
                label="Descrição Tesouraria"
                name="des_tipo"
                placeholder="Informe a Descrição"
                maxLength={25}
                caseInput="upper"
                register={register}
                isError={!!errors.des_tipo}
                control={control}
              />
            </Col>
            <Col md={2} className="mt-4">
              <div className="col-sm-12 col-md-1 mt-3">
                <ToggleDefault
                  labelText="Provento?"
                  setChecked={flg_proventoo}
                  onSwitch={() => setValue('flg_provento', !flg_proventoo)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3} sm={12} className="mt-4">
              <InputSelect
                label="Tipo"
                placeholder="Selecione…"
                name="tipo_mov"
                register={register}
                isError={iniMov && !!errors.tipo_mov}
                control={control}
                options={[
                  { value: 0, label: 'ENTRADA' },
                  { value: 1, label: 'SAÍDA' },
                ]}
                changeSelected={(selected) => {
                  setIniMov(false);
                  setValue('tipo_mov', selected);
                }}
              />
            </Col>
            <Col md={3} sm={12} className="mt-4">
              <InputSelect
                label="Operação"
                placeholder="Selecione…"
                name="tipo_operacao"
                register={register}
                control={control}
                options={[
                  { label: 'OUTROS', value: 0 },
                  { label: 'ABERTURA', value: 1 },
                  { label: 'FECHAMENTO', value: 2 },
                ]}
                changeSelected={(selected) => {
                  setIniTipo(false);
                  setValue('tipo_operacao', selected);
                }}
                isError={iniTipo && !!errors.tipo_operacao}
              />
            </Col>
            <Col md={6} sm={12} className="mt-4">
              <Categoria
                value={categoria}
                setInvalid={!!errors.cod_categoria}
                isRequired
                iniInicializado
                onChange={(newValue: any) => {
                  handleChangeCategoria(
                    newValue,
                    typeof newValue.value !== 'number',
                  );
                }}
              />
            </Col>
          </Row>
        </FormDefault>
      )}
    </Container>
  );
};

export default TipoDeMovimentoDeCaixa;
